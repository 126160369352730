// @flow
import cn from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Context as ScreenSizeContext } from 'app/rescript/contexts/ScreenSizeContext.bs.js';
import { buildImageUrlFromJs as buildImageUrl } from 'app/rescript/libs/AwsImageHandlerV5.bs.js';
import * as gtag from 'libs/gtag';
import SearchListingExample from 'rescript/styleguide/components/SearchListingExample/SearchListingExample.bs.js';
import AirbnbIcon from 'rescript/styleguide/icons/AirbnbIcon.bs.js';
import BookingcomFullIcon from 'rescript/styleguide/icons/BookingcomFullIcon.bs.js';
import HouseIcon from 'rescript/styleguide/icons/HouseIcon.bs.js';
import VrboIcon from 'rescript/styleguide/icons/VrboIcon.bs.js';
import { HeroExampleBox } from 'styleguide/components';
import HicheeAutocompleteSearch from 'styleguide/components/HicheeAutocompleteSearch/HicheeAutocompleteSearch';
import type { ExampleListing } from 'types';

import ScreenSize from 'app/enums/ScreenSize';
import { NAVBAR_HEIGHT } from 'app/styleguide/styles/misc/variables';
import css from './Hero.scss';

type Props = {
  contextualListingsPath: string,
  desktop: boolean,
  tablet: boolean,
  mobile: boolean,
  titleContent: ?string,
  exampleListing: ExampleListing,
  hasFocusedSearchBar: boolean,
  onFocusSearchBar: (focusedOnSearchByCity: boolean) => void,
  showSearchByCity: boolean,
  toggleSearchBar: () => void,
};

const SearchCityOrListing = (props: Props) => (
  <div className={css.searchBarWrapper}>
    <div className={css.searchBar}>
      <HicheeAutocompleteSearch
        contextualListingsPath={props.contextualListingsPath}
        desktop={props.desktop}
        tablet={props.tablet}
        mobile={props.mobile}
        notifyGtagOnSearch={(location: string) => {
          gtag.sendEvent({
            category: 'WelcomePage',
            action: 'Search Form Submission',
            label: location,
          });
        }}
        shouldHideUrlInstructions
        notifyGtagOnUpdate={() => {
          gtag.sendEvent({
            category: 'WelcomePage',
            action: 'Search Form Interaction',
          });
        }}
        loadingMessage="Searching for locations, property owners, and listings..."
        hasFocusedSearchBar={props.hasFocusedSearchBar}
        onFocusSearchBar={props.onFocusSearchBar}
      />
    </div>
  </div>
);

const SearchListing = (props: Props) => {
  // $FlowFixMe
  const searchBarRef = React.useRef(null);
  // $FlowFixMe
  const screen = React.useContext(ScreenSizeContext.x);

  const handleFocus = (focusedOnSearchByCity: boolean) => {
    if (screen === ScreenSize.SM || screen === ScreenSize.MD) {
      if (searchBarRef.current) {
        const boundaries = searchBarRef.current.getBoundingClientRect();
        window.scrollTo({
          top: boundaries.top + window.scrollY - NAVBAR_HEIGHT,
        });
      }
    }

    if (props.onFocusSearchBar) props.onFocusSearchBar(focusedOnSearchByCity);
  };

  return (
    <div className={css.searchBarWrapper} ref={searchBarRef}>
      <div className={css.searchBar}>
        <HicheeAutocompleteSearch
          contextualListingsPath={props.contextualListingsPath}
          desktop={props.desktop}
          tablet={props.tablet}
          mobile={props.mobile}
          instructions={<SearchListingExample exampleListing={props.exampleListing} />}
          urlSearchBar
          notifyGtagOnSearch={(location: string) => {
            gtag.sendEvent({
              category: 'WelcomePage',
              action: 'Search Form Submission',
              label: location,
            });
          }}
          notifyGtagOnUpdate={() => {
            gtag.sendEvent({
              category: 'WelcomePage',
              action: 'Search Form Interaction',
            });
          }}
          notFoundMessage={`No results found. Please check your URL and try again.\nIf still no results, please contact us via our Help tab.`}
          loadingMessage="Searching for the listing and prices..."
          hasFocusedSearchBar={props.hasFocusedSearchBar}
          onFocusSearchBar={handleFocus}
        />
      </div>
    </div>
  );
};

const ToggleSearchBar = ({ onClick, text }) => (
  <div className={css.toggleSearchBarsWrapper}>
    Or search by&nbsp;
    <a
      className={css.toggleSearchBars}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {text}
    </a>
  </div>
);

const Provider = props => <div className={css.provider}>{props.children}</div>;

const Hero = (props: Props) => {
  const StackedListings = () => {
    const backgroundImageUrl = buildImageUrl({
      url: 'images/static/homepage-featured-listing-min_ihrnm5.webp',
    });

    return (
      <div className={css.stackedListingsContainer}>
        <div className={css.stackedListings}>
          <div
            className={cn(css.stackedListing, css.listingRight)}
            style={{
              backgroundImage: `url("${backgroundImageUrl}")`,
            }}
          />
          <div
            className={cn(css.stackedListing, css.listingLeft)}
            style={{
              backgroundImage: `url("${backgroundImageUrl}")`,
            }}
          />

          <HeroExampleBox exampleListing={props.exampleListing} mobile={props.mobile} />
        </div>
      </div>
    );
  };

  const heroImageUrl = buildImageUrl({ url: 'images/static/homepage-hero-2.webp' });

  const heroImageSources = (
    <>
      <source
        media="(max-width: 428px)"
        srcSet={`
      ${buildImageUrl({ url: heroImageUrl, width: 428, height: 930 })},
      ${buildImageUrl({ url: heroImageUrl, width: 428 * 2, height: 930 * 2 })} 2x,
      ${buildImageUrl({ url: heroImageUrl, width: 428 * 3, height: 930 * 3 })} 3x,
    `}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`
      ${buildImageUrl({ url: heroImageUrl, width: 768 })},
      ${buildImageUrl({ url: heroImageUrl, width: 768 * 2 })} 2x,
      ${buildImageUrl({ url: heroImageUrl, width: 768 * 3 })} 3x,
    `}
      />
      <source
        media="(max-width: 1079px)"
        srcSet={`
      ${buildImageUrl({ url: heroImageUrl, width: 1080 })},
      ${buildImageUrl({ url: heroImageUrl, width: 1080 * 2 })} 2x,
      ${buildImageUrl({ url: heroImageUrl, width: 1080 * 3 })} 3x,
    `}
      />
      <source
        media="(max-width: 1439px)"
        srcSet={`
      ${buildImageUrl({ url: heroImageUrl, width: 1440 })},
      ${buildImageUrl({ url: heroImageUrl, width: 1440 * 2 })} 2x,
      ${buildImageUrl({ url: heroImageUrl, width: 1440 * 3 })} 3x,
    `}
      />
      <source
        media="(max-width: 2559px)"
        srcSet={`
      ${buildImageUrl({ url: heroImageUrl, width: 2560 })},
      ${buildImageUrl({ url: heroImageUrl, width: 2560 * 2 })} 2x,
      ${buildImageUrl({ url: heroImageUrl, width: 2560 * 3 })} 3x,
    `}
      />
      <source
        srcSet={`
      ${heroImageUrl},
      ${heroImageUrl} 2x,
      ${heroImageUrl} 3x,
    `}
      />
    </>
  );

  return (
    <div className={css.heroContainer}>
      {/*
        * Prefer inline image to CSS background image to hint to the browser that this image has
        * high fetch priority to optimize the LCP resource. The inline styling is also for
        * optimizing the LCP resource, as there's no need to wait for a CSS file processing.
        * Read more at https://web.dev/priority-hints/.
        * */}
      <picture>
        {heroImageSources}
        <img
          className={css.heroBackgroundImage}
          alt="Homepage background"
          fetchpriority="high"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            bottom: '0',
            minWidth: '100%',
            maxWidth: '100%',
            minHeight: '100%',
            maxHeight: '100%',
            objectFit: 'cover',
          }}
          width="100%"
          height="100%"
          src={heroImageUrl}
        />
      </picture>
      {/*
        * We couldn't preload the LCP image setting the image source via inline style. Thus, the
        * overlay's inline style had to be implemented as a separate div.
        */}
      <div
        className={css.heroBackgroundOverlay}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
          background: `
            linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%) left top no-repeat,
            linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.0854167) 50.52%, rgba(0, 0, 0, 0.16) 100%) left top
          `,
        }}
      />
      <div className={css.hero}>
        <div className={cn(css.searchForm, css.secondBarMargin)}>
          <div className={cn(css.header, { [css.headerMobile]: props.mobile })}>
            <div className={css.headlines}>
              <div className={css.superHeadline}>
                <Provider>
                  <AirbnbIcon className={css.superHeadlineIcon} colors="White" /> Airbnb
                </Provider>
                <Provider>
                  <BookingcomFullIcon className={css.superHeadlineIcon} colors="White" /> Booking.com
                </Provider>
                <Provider>
                  <VrboIcon className={css.superHeadlineIcon} colors="White" /> Vrbo
                </Provider>
                <Provider>
                  <HouseIcon className={css.superHeadlineIcon} color="White" /> Direct
                </Provider>
              </div>
              <h2 className={css.subheadline}>
                <FormattedMessage id="seo.tagline.with-hichee" defaultMessage="With HiChee," />
              </h2>
              <h1 className={css.headline} data-cy="headline">
                <FormattedMessage
                  id="welcome.hero.title"
                  defaultMessage={props.titleContent}
                  values={{
                    dont: <span className={css.headlineFirstWord}>Don&apos;t</span>,
                    airbnb: <span className={css.headlineSecondWord}>Airbnb</span>,
                  }}
                />
              </h1>
            </div>

            {props.exampleListing && (
              <div className={cn(css.featuredListing, css.showMd)} data-test-id="example-listing-mobile">
                <StackedListings />
              </div>
            )}

            <div className={css.searchBarsWrapper}>
              {!props.showSearchByCity && <SearchListing {...props} />}
              {props.showSearchByCity && <SearchCityOrListing {...props} />}
              <ToggleSearchBar
                onClick={props.toggleSearchBar}
                text={props.showSearchByCity ? 'Listing Link' : 'Location'}
              />
            </div>
          </div>

          {props.exampleListing && (
            <div className={cn(css.featuredListing, css.hideMd)} data-test-id="example-listing-desktop">
              <StackedListings />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
