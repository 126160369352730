// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Route = require("../../../Route.bs.js");
var Style = require("../../../libs/Style.bs.js");
var React = require("react");
var Button = require("../Button/Button.bs.js");
var Carousel = require("../Carousel/Carousel.bs.js");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var ReactIntl = require("react-intl");
var Caml_option = require("rescript/lib/js/caml_option.js");
var JsxPPXReactSupport = require("rescript/lib/js/jsxPPXReactSupport.js");
var FansReviewsModuleScss = require("./FansReviews.module.scss").default;

var css = FansReviewsModuleScss;

var scrollingParams = {
  margin: 10.0,
  width: 315.0,
  step: 340.0,
  duration: 300,
  tolerance: 1.0
};

function FansReviews$VideoBlock(props) {
  var onLoad = props.onLoad;
  var elementRef = React.useRef(null);
  React.useEffect(function () {
        Curry._1(onLoad, elementRef);
      });
  return React.createElement("div", {
              ref: Caml_option.some(elementRef),
              className: css.videoContainer,
              style: {
                marginRight: Style.pxOfFloat(10.0),
                marginLeft: Style.pxOfFloat(10.0)
              },
              dangerouslySetInnerHTML: {
                __html: props.embedCode
              }
            });
}

var VideoBlock = {
  make: FansReviews$VideoBlock
};

function FansReviews(props) {
  var fansVideos = props.fansVideos;
  if (fansVideos.length !== 0) {
    return React.createElement(WithTestId.make, {
                id: "fans-videos-section",
                children: React.createElement("div", {
                      className: css.container
                    }, React.createElement("div", {
                          className: css.title
                        }, React.createElement(ReactIntl.FormattedMessage, {
                              id: "fans-reviews.title",
                              defaultMessage: "Travel columnists and influencers recommend HiChee!"
                            })), React.createElement(Carousel.make, {
                          items: fansVideos,
                          renderBlock: (function (item, onLoad) {
                              return JsxPPXReactSupport.createElementWithKey(item, React.Fragment, {
                                          children: React.createElement(FansReviews$VideoBlock, {
                                                embedCode: item,
                                                onLoad: onLoad
                                              })
                                        });
                            }),
                          compareEquality: (function (item1, item2) {
                              return item1 === item2;
                            }),
                          wrapperStyle: {
                            maxWidth: "990px"
                          },
                          scrollingParams: scrollingParams,
                          prevControlTitle: "Previous Review",
                          prevControlTestId: "fans-reviews-carousel-prev",
                          prevControlClassName: css.controlBtn,
                          nextControlClassName: css.controlBtn,
                          containerClassName: css.carouselContainer,
                          nextControlTestId: "fans-reviews-carousel-next",
                          nextControlTitle: "Next Review",
                          controlStyle: /* Side */0,
                          withScrollbar: false
                        }), React.createElement("div", {
                          className: css.buttonWrapper
                        }, React.createElement(Button.AsNativeLink.make, {
                              href: Route.fans,
                              target: /* Blank */{
                                rel: {
                                  referrer: false,
                                  follow: false
                                }
                              },
                              size: /* MD */1,
                              color: /* Primary */0,
                              children: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "fan-reviews.button",
                                    defaultMessage: "See all fans reviews"
                                  })
                            })))
              });
  } else {
    return null;
  }
}

var make = FansReviews;

exports.css = css;
exports.scrollingParams = scrollingParams;
exports.VideoBlock = VideoBlock;
exports.make = make;
/* css Not a pure module */
