// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("../Button/Button.bs.js");
var ReactIntl = require("react-intl");
var ModalDialog = require("../Modal/ModalDialog.bs.js");
var WeGotAProblem = require("../../svgs/WeGotAProblem.bs.js");
var HiddenListingModalModuleScss = require("./HiddenListingModal.module.scss").default;

var css = HiddenListingModalModuleScss;

function HiddenListingModal(props) {
  var onHide = props.onHide;
  return React.createElement(ModalDialog.make, {
              modal: props.modal,
              className: css.modal,
              desktopPosition: /* Centered */0,
              mobilePosition: /* Docked */0,
              testId: "hidden-listing-modal",
              children: React.createElement("div", {
                    className: css.wrapper
                  }, React.createElement("div", {
                        className: css.leftSection
                      }, React.createElement("div", {
                            className: css.message
                          }, React.createElement(ReactIntl.FormattedMessage, {
                                id: "hidden-listing-modal.message",
                                defaultMessage: "Sorry, the host has chosen not to display listings on HiChee"
                              })), React.createElement(Button.make, {
                            size: /* MD */1,
                            color: /* Primary */0,
                            className: css.backToHomeBtn,
                            onClick: (function (param) {
                                Curry._1(onHide, undefined);
                              }),
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "hidden-listing-modal.back-to-home",
                                  defaultMessage: "Back to Home"
                                })
                          })), React.createElement("div", {
                        className: css.rightSection
                      }, React.createElement(WeGotAProblem.make, {
                            className: css.weGotAProblem
                          })))
            });
}

var make = HiddenListingModal;

var $$default = HiddenListingModal;

exports.css = css;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* css Not a pure module */
